import { Route, Routes, useLocation } from "react-router-dom";
import "./css/App.css";

import { useEffect } from "react";
import { routeTitles } from "./utils/constants";
import ComingSoon from "./containers/landingPage/ComingSoon";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import TermsAndCondtions from "./containers/termsAndCondtions/TermsAndCondtions";
import { createGlobalStyle } from "styled-components";
import TermsAndCondtionsApp from "./containers/terms&conditions/TermsAndCondtionsApp";
import PrivacyPolicy from "./containers/privacyPolicy/privacyPolicy";

export default function App() {
  const location = useLocation();
  const GlobalStyle = createGlobalStyle`
  body {
    background: ${location.pathname==='/terms-and-conditions' ? "transparent":location.pathname==='/terms-&-conditions'?'#0B0A0A' : "#151313"};
    color: ${(location.pathname==='/terms-&-conditions') ? "#FFF" :location.pathname==='/terms-and-conditions'?"black":""};
/* backdrop-filter: ${location.pathname==='/terms-and-conditions' && 'blur(12px)' } ; */
  }
`;
  useEffect(() => {
    const currentRoute = location.pathname;
    const title = routeTitles[currentRoute] || "Your Default Title";
    document.title = title;
  }, [location]);

  return (
    <><GlobalStyle/>
    <Routes>
      <Route
        exact
        path="/"
        element={
            <ComingSoon />
        }
      />
       <Route
        exact
        path="/terms-and-conditions"
        element={
            <TermsAndCondtions />
        }
      />
      <Route
        exact
        path="/privacy-policy"
        element={
            <PrivacyPolicy />
        }
      />
       <Route
        exact
        path="/terms-&-conditions"
        element={
            <TermsAndCondtionsApp />
        }
      />
    </Routes>
    </>
  );
}
