import React, { useState, useEffect } from "react";
import '../../css/ComingSoon.css';

import {Section1,Section2,Section3,Section4,Section5,Section6,MainNavigation,settings} from './components'
const ComingSoon = () => {
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);
  const [isOverlayVisible, setIsOverlayVisible] = useState(true);

  useEffect(() => {
    console.log("isOverlayVisible", isOverlayVisible);
    if (isOverlayVisible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    const handleResize = () => {
        setInnerWidth(window.innerWidth);
      };
      window.addEventListener("resize", handleResize);
  
      return () => {
        window.removeEventListener("resize", handleResize);
      };    
  }, [isOverlayVisible]);


  return (
    <>
      <div
        className={`flex items-center justify-center flex-col py-4 w-full relative login-bg  showcase`} style={{backgroundColor:"rgb(0 0 0)"}}>
          <div className="video-container mt-16 md:mt-[6rem]">

          <video autoPlay loop muted playsInline >
            <source src='https://somos-frontend-file.s3.amazonaws.com/Webapp/landing-bg.mov' type="video/mp4"/>
          </video>
          </div>
        <MainNavigation className="main-navigation" />
        {isOverlayVisible && (
          <div className="overlay">
            <h1 className="overlay-text">Coming Soon</h1>
          </div>
        )}
        {/** crypto section */}
        <Section1 innerWidth={innerWidth} />
      </div>
      {/** keep it smart section */}
      <Section2 settings={settings} innerWidth={innerWidth} />

      {/** what's new section */}
      <Section3 />

      {/** Plans Section */}
      <Section4 settings={settings} innerWidth={innerWidth} />

      {/** Scholarships Section */}
      <Section5 innerWidth={innerWidth} />

      {/** Footer */}
      <Section6 innerWidth={innerWidth} />
    </>
  );
};

export default ComingSoon;
